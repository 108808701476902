import React from "react"
import { Skeleton } from "antd"
import QueryString from "query-string"
import ErrorScreen from "../components/ErrorScreen"
import { translatePath } from "../utils"
import { gql, useQuery } from "@apollo/client"
import { navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"

const GET_SEO_QUERY = gql`
  query getSeo($id: uuid) {
    seo(where: { item_id: { _eq: $id } }) {
      slug
      item_type
    }
  }
`

const GoPage = ({ location }) => {
  const { isLoading } = useAuth0()
  const params = QueryString.parse(location.search)
  const { loading, error, data } = useQuery(GET_SEO_QUERY, {
    variables: { id: params.id },
  })

  if (loading || isLoading) {
    return <Skeleton active round />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const seo = data.seo[0]
  if (!seo) {
    navigate("/404", { replace: true })
  } else {
    navigate(`/${translatePath(seo.item_type)}/${seo.slug}`, { replace: true })
  }

  return <Skeleton active round />
}

export default GoPage
